import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';
import { toast } from 'react-toastify';

import Model from '../components/model';
import Loader from '../components/Loader';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import StepList from '../components/StepList';
import PriceWidget from '../components/steps/PriceWidget';
import PrivateRoute from '../components/auth/protected';
import { fetchItemConfigs } from '../state/itemConfigs/thunks';
import {
  setItemType,
  setMaterial,
  setPolish,
} from '../state/create/jewelry/thunks';
import CustomProgressBar from '../components/ProgressBar';
import 'react-toastify/dist/ReactToastify.css';
import Banner from '../components/Banner';
import ProductRender from '../components/ProductRender';
import {
  isProductChosen,
  setSelectedProduct,
} from '../state/create/jewelry/actions';

const ModelPage = ({
  file,
  fileName,
  isLoading,
  fetchItemConfigs,
  setMaterial,
  setPolish,
  profile,
  loadProgress,
  fileDimensionError,
  model_height,
  model_width,
  model_length,
  new_order,
  selectedProduct,
  productChosen,
  quantity,
  isProductChosen,
  setSelectedProduct,
  setItemType,
  itemConfigs,
}) => {
  const modelRef = useRef();
  const [itmConfs, setItmConf] = useState([])

  const fetchAllItemConfigs = async () => {
    const itemConfigss = await fetchItemConfigs();
    setItmConf(itemConfigss)

    const variants = itemConfigss.map((itemConfig) => itemConfig.variant);

    const sterlingSilver = variants.find(
      (variant) => variant.code.toLowerCase() === 'ssa1'
    );

    const noPolish = variants.find(
      (variant) => variant.code.toLowerCase() === 'satin polish'
    );

    setMaterial(productChosen ? null : sterlingSilver);
    setPolish(productChosen ? null : noPolish);
  };

  useEffect(() => {
    if (itmConfs.length === 0) {
      fetchAllItemConfigs();
    }
  }, [productChosen]);

  useEffect(() => {
    if (document) {
      document.body.classList.add('gradient');
      return () => document.body.classList.remove('gradient');
    }
  }, []);

  const showError = () => toast.error('That file is not supported');

  const query = graphql`
    query {
      wordpressWpMessage(wordpress_id: { eq: 409 }) {
        id
        title
        content
      }
    }
  `;

  const [productPricing, setProductPricing] = useState(null);
  const [materialPrice, setMatPrice] = useState(null);
  const [platingPrice, setPlatingPrice] = useState(null);
  const [polishPrice, setPolishPrice] = useState(null);
  const [assemblyPrice, setAssemblyPrice] = useState(null);
  const [laserEngravingPrice, setLaserEngravingPrice] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);

  const [showQtyNotification, setShowQtyNotification] = useState(false);
  const [msgQtyNotif, setMsgQtyNotif] = useState(null);
  const [unlockDiscount, setUnlockDiscount] = useState(false);

  const prodDiscount = selectedProduct
    ? selectedProduct.has_discount
      ? JSON.parse(selectedProduct.discount)
      : null
    : null;

  let assemblyDiscount = 0;
  if (selectedProduct) {
    if (selectedProduct.has_discount) {
      for (const discount of prodDiscount) {
        if (quantity >= discount.quantity) {
          assemblyDiscount = discount.discount;
        }
      }
    }
  }

  const disPrice = productPricing - (assemblyDiscount / 100) * productPricing;

  const totalProductPrice =
    disPrice +
    materialPrice +
    platingPrice +
    polishPrice +
    assemblyPrice +
    laserEngravingPrice;

  useEffect(() => {
    if (!productPricing && productChosen) {
      isProductChosen(false);
      setSelectedProduct(null);
      setItemType(null);
    }
  }, [productChosen]);

  return (
    <PrivateRoute showLock>
      {profile && !profile.answers ? <Banner /> : null}
      <Layout className='justify-content-end '>
        <SEO title='3D Model' />
        <Row className='renderContainer'>
          <Col className='renderColumn'>
            {productChosen ? (
              <>
                <ProductRender product={selectedProduct} />
              </>
            ) : (
              <>
                <Model
                  forwardedRef={modelRef}
                  file={file}
                  fileName={fileName}
                  onError={showError}
                  assemblyStep={false}
                />
                {!!isLoading && (
                  <Loader msg='Loading Model' format='middle-page' />
                )}
                {isLoading ? (
                  <div className='custom-progress-bar'>
                    <CustomProgressBar completed={loadProgress} />
                  </div>
                ) : null}
                {fileDimensionError && !isLoading && (
                  <>
                    <div className='dimensions-error'>
                      <p>
                        Your 3D Model is too small to calculate the price.
                        Please check the 3D model and try again or Design It
                        With Us as your design is not optimized for production.
                        Please email hello@gildform.com for further assistance.{' '}
                      </p>
                    </div>
                    {/* {!new_order && ( */}
                    <div className='model-dimensions with-errors'>
                      <div className='dimension-box'>
                        <span className='label'>Width:</span>
                        <span className='value'>{model_width} mm</span>
                      </div>
                      <div className='dimension-box'>
                        <span className='label'>Height:</span>
                        <span className='value'>{model_height} mm</span>
                      </div>
                      <div className='dimension-box'>
                        <span className='label'>Length:</span>
                        <span className='value'>{model_length} mm</span>
                      </div>
                    </div>
                    {/* )} */}
                  </>
                )}
                {!isLoading &&
                  !fileDimensionError &&
                  // !new_order &&
                  model_height &&
                  model_width &&
                  model_length ? (
                  <div className='model-dimensions'>
                    <div className='dimension-box'>
                      <span className='label'>Width:</span>
                      <span className='value'>{model_width} mm</span>
                    </div>
                    <div className='dimension-box'>
                      <span className='label'>Height:</span>
                      <span className='value'>{model_height} mm</span>
                    </div>
                    <div className='dimension-box'>
                      <span className='label'>Length:</span>
                      <span className='value'>{model_length} mm</span>
                    </div>
                  </div>
                ) : null}
              </>
            )}
            <PriceWidget
              productionPage={false}
              productChosen={productChosen}
              materialPrice={materialPrice}
              polishPrice={polishPrice}
              platingPrice={platingPrice}
              assemblyPrice={assemblyPrice}
              laserEngravingPrice={laserEngravingPrice}
              itemPrice={itemPrice}
              productPricing={productPricing}
              disPrice={disPrice}
              assemblyDiscount={assemblyDiscount}
              setShowQtyNotification={setShowQtyNotification}
              showQtyNotification={showQtyNotification}
              setMsgQtyNotif={setMsgQtyNotif}
              msgQtyNotif={msgQtyNotif}
              setUnlockDiscount={setUnlockDiscount}
              unlockDiscount={unlockDiscount}
            />
            <Row style={{ marginBottom: '10px' }}>
              <StaticQuery
                query={query}
                render={(data) => (
                  <p
                    style={{ marginLeft: '50px', marginRight: '50px' }}
                    dangerouslySetInnerHTML={{
                      __html: data.wordpressWpMessage.content,
                    }}
                  />
                )}
              />
            </Row>
          </Col>
          <StepList
            takeScreenShot={() => modelRef.current.takeScreenShot()}
            productChosen={productChosen}
            productionPage={false}
            selectedProduct={selectedProduct}
            setPolishPrice={setPolishPrice}
            setMaterialPrice={setMatPrice}
            setAssemblyPrice={setAssemblyPrice}
            setLaserEngravingPrice={setLaserEngravingPrice}
            setPlatingPrice={setPlatingPrice}
            setProductPricing={setProductPricing}
            totalProductPrice={totalProductPrice}
            laserEngravingPrice={laserEngravingPrice}
            materialPrice={materialPrice}
            platingPrice={platingPrice}
            polishPrice={polishPrice}
            assemblyPrice={assemblyPrice}
            itemConfigs={itemConfigs}
            setShowQtyNotification={setShowQtyNotification}
            showQtyNotification={showQtyNotification}
            setMsgQtyNotif={setMsgQtyNotif}
            setUnlockDiscount={setUnlockDiscount}
          />
        </Row>
      </Layout>
    </PrivateRoute>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs.configs,
  materials: state.itemConfigs.materials,
  file: state.create.jewelry.file,
  fileName: state.create.jewelry.file_name,
  isLoading: state.create.jewelry.isLoading.modelIsLoading,
  profile: state.login.auth && state.login.auth.profile,
  loadProgress: state.create.jewelry && state.create.jewelry.loadProcess,
  fileDimensionError:
    state.create.jewelry && state.create.jewelry.fileDimensionError,
  model_height: state.create.jewelry && state.create.jewelry.model_height,
  model_width: state.create.jewelry && state.create.jewelry.model_width,
  model_length: state.create.jewelry && state.create.jewelry.model_length,
  new_order: state.create.jewelry && state.create.jewelry.new_order,
  selectedProduct: state.create.jewelry && state.create.jewelry.selectedProduct,
  productChosen: state.create.jewelry && state.create.jewelry.productChosen,
  quantity: state.create.jewelry && state.create.jewelry.quantity,
});

const mapDispatchToProps = (dispatch) => ({
  fetchItemConfigs: () => dispatch(fetchItemConfigs()),
  setMaterial: (material) => dispatch(setMaterial(material)),
  setPolish: (polish) => dispatch(setPolish(polish)),
  setSelectedProduct: (data) => dispatch(setSelectedProduct(data)),
  isProductChosen: (data) => dispatch(isProductChosen(data)),
  setItemType: (type) => dispatch(setItemType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModelPage);
