import React from 'react';
import { Carousel, OverlayTrigger, Tooltip } from 'react-bootstrap';

const ProductRender = (props) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id='tooltip-bottom' style={{ fontSize: '16px' }}>
          {props.product && props.product.prodImages.length > 1
            ? `Click the arrows to view all the images!`
            : null}
        </Tooltip>
      }
      placement={'top'}
    >
      <div
        className='carousel-content'
        style={{
          width: '90%',
          minHeight: '22rem',
          marginBottom: '2rem',
          boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
        }}
      >
        <Carousel
          activeIndex={props.index}
          onSelect={props.handleSelect}
          interval={null}
          variant={'dark'}
          prevIcon={
            <i
              className='fas fa-arrow-left'
              style={{ fontSize: '16px', color: 'black' }}
            />
          }
          nextIcon={
            <i
              className='fas fa-arrow-right'
              style={{ fontSize: '16px', color: 'black' }}
            />
          }
        >
          {props.product &&
            props.product.prodImages &&
            props.product.prodImages.map((item, idx) => {
              return (
                <Carousel.Item
                  className='carousel-item'
                  key={idx}
                  style={{ marginLeft: '10px' }}
                >
                  <div key={idx}>
                    <img
                      src={item}
                      style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        maxHeight: '70vh',
                      }}
                      alt='carousel item'
                    />
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
    </OverlayTrigger>
  );
};

export default ProductRender;
