import React from 'react'

const ProgressBar = (props) => {

    const {completed } = props;

    const containerStyles = {
        height: 20,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: 50,
        textAlign: "center",
        justifyContent: "center",
        color: "#7c6262"
      }
      
      const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: "#7c6262",
        borderRadius: 'inherit',
        textAlign: 'right'
      }
    

    return (
      <div className="progress-bar-container">
        <div style={fillerStyles}>
        </div>
        {`${completed}%`}
      </div>
    );
  };

export default ProgressBar;